import { theme } from 'theme';
import { themeColors } from 'theme/themeColors';

export const styles = {
  root: {
    paddingTop: '40px',
    paddingBottom: '40px',
    backgroundColor: themeColors.lightBlack,
    [theme.breakpoints.down('lg')]: {
      paddingTop: '80px',
    },
    '& p': {
      lineHeight: '30px',
    },
  },
  text: {
    color: themeColors.greyPrimary,
    '& > a': {
      color: themeColors.greyPrimary,
      textDecoration: 'none',
      transition: 'color linear 200ms',
    },
    '& > a:active, & > a:hover': {
      color: theme.palette.common.white,
    },
  },
  helpLinks: {
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
};
