import { AD_TYPE, Icon, Image, Video } from './globalTypes';

export enum AD_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
}

export enum LANGUAGE {
  NL = 'NL',
  FR = 'FR',
  DK = 'DK',
}

export enum COUNTRY {
  NL = 'NL',
  BE = 'BE',
  DK = 'DK',
}

export interface Creative {
  advertiserCountry: COUNTRY;
  landingPage: string;
  name: string;
  language: LANGUAGE;
  mediaData: {
    headline?: string;
    body?: string;
    cta?: string;
    primaryText?: string;
    sponsoredBy?: string;
  };
  format: AD_TYPE;
  files: {
    name: string;
    fileUrl: string;
    relationType: 'IMAGE' | 'VIDEO' | 'ICON';
    vastTag?: string;
    videoPreviewFileUrl?: string;
  }[];
}
